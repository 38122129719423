<template>
  <v-form class="login-form" @submit.prevent="onSubmit" ref="form">
    <v-alert
      v-if="$store.getters.getLoginAgainText"
      type="info"
      icon="mdi-shield-lock-outline"
      >{{ $store.getters.getLoginAgainText }}</v-alert
    >
    <div v-if="loginByEmail">
      <InputElement
        :rules="[rules.required, rules.email]"
        label="Email"
        v-model="credentials.email"
      />

      <PasswordInputElement
        label="Heslo"
        type="password"
        :rules="[rules.required]"
        v-model="credentials.password"
      />
      <router-link to="/reset-hesla" class="white--text text-right mb-4 d-block"
        >Zapomněli jste heslo?</router-link
      >
    </div>

    <div v-else>
      <v-alert text type="info" color="blue lighten-4" class="blue--text">
        <p>
          Pokud užíváte léky na cukrovku od společnosti Novo Nordisk, přihlašte
          se do uzavřené sekce opsáním čísla šarže léčivého přípravku, který vám
          předepsal váš lékař.
        </p>
        <v-divider class="mb-4" />
        <p>
          <strong> Kde naleznu číslo šarže a jak číslo šarže vypadá?</strong>
          <br />
          Číslo šarže je 7místný kód, který naleznete na krabičce vašeho
          léčivého přípravku.
        </p>
      </v-alert>
      <div v-for="(item, i) in batchNumbers" :key="i">
        <InputElement
          label="Číslo šarže"
          :rules="[rules.required]"
          v-model="item.batchNumber"
        />
        <div class="d-flex justify-space-between mb-4">
          <div>
            <div
              v-if="i + 1 === batchNumbers.length"
              class="text-btn d-flex"
              @click="addBatchNumber"
            >
              <v-icon color="white">mdi-plus</v-icon>
              <div class="text white--text ml-2">Přidat další šarži</div>
            </div>
          </div>
          <div
            v-if="i > 0"
            class="text-btn d-flex justify-end"
            @click="removeBatchNumber(i)"
          >
            <v-icon color="white">mdi-delete</v-icon>
            <div class="text white--text ml-2">Odebrat šarži</div>
          </div>
        </div>
      </div>
    </div>

    <ButtonElement
      type="submit"
      text="Vstoupit"
      color="accent"
      class="mb-8"
      :loading="loading"
    />
    <div class="white--text text-center">
      Pro snadnější přístup se můžete
      <span
        class="text-decoration-underline cursor-pointer"
        @click="$router.push({ name: 'Registration' })"
        >zaregistrovat</span
      >
    </div>
  </v-form>
</template>

<script>
import ButtonElement from "../elements/ButtonElement.vue";
import InputElement from "../elements/InputElement.vue";
import validation from "../../use/validations";
import useApicall from "../../use/apiCall";
import PasswordInputElement from "../elements/PasswordInputElement.vue";

export default {
  components: { InputElement, ButtonElement, PasswordInputElement },
  props: {
    loginByEmail: {
      type: Boolean,
      required: true,
    },
  },

  setup() {
    const { postData, getRawData } = useApicall();
    return {
      postData,
      getRawData,
    };
  },

  data() {
    return {
      loading: false,
      credentials: {
        email: null,
        password: null,
      },
      batchNumbers: [
        {
          batchNumber: "",
        },
      ],
      messages: null,
      rules: validation,
    };
  },

  computed: {
    query() {
      return this.$route.query.redirect;
    },
  },

  methods: {
    async onSubmit() {
      this.loginByEmail ? await this.signIn() : await this.sendBatchCode();
    },

    async sendBatchCode() {
      this.loading = true;
      const params = new URLSearchParams();
      this.batchNumbers.forEach((element) => {
        params.append("batchNumbers", element.batchNumber.toUpperCase());
      });

      const request = {
        params: params,
      };

      const response = await this.getRawData(
        "/medicines/validate-batch-numbers",
        request,
        true
      );

      this.loading = false;

      if (response) {
        this.$store.commit("setBatchNumbers", this.batchNumbers);
        this.$store.dispatch("fetchProducts");
        this.checkQueryAndRedirect();
      }
    },

    async signIn() {
      this.loading = true;
      const userInfo = await this.postData(
        "/auth",
        {
          credentials: this.credentials,
          staySignedIn: true,
          isMobile: false,
        },
        null,
        true
      );

      this.loading = false;

      if (userInfo) {
        if (userInfo.user.role === "nurse") {
          this.$store.commit(
            "setLoginAgainText",
            "Pokoušíte se přihlásit jako sestra, prosím využijte přihlašovací stránku pro sestry."
          );
          return;
        }
        await this.$store.commit("setUserInfo", userInfo);
        this.checkQueryAndRedirect();
      }
    },

    checkQueryAndRedirect() {
      if (this.query) {
        this.$router.push({
          path: this.query,
        });
      } else {
        this.$router.push({
          name: "Products",
        });
      }
    },

    addBatchNumber() {
      this.batchNumbers.push({
        batchNumber: "",
      });
    },
    removeBatchNumber(index) {
      this.batchNumbers.splice(index, 1);
    },

    getErrorMessage(error) {
      const errorArray = error.response.data.errors;
      const errorMessageArray = errorArray.map((er) => er.message);
      this.messages = errorMessageArray;
      this.messages.type = "error";
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  .button-element {
    width: 100%;
  }

  .text-btn {
    cursor: pointer;

    &:hover {
      .text {
        text-decoration: underline;
      }
    }
  }
}
</style>
